<template>
  <div class="popup" @click="closePopup" v-if="showFlag">
    <div class="popup-content" @click.stop>
      <span class="close-button" @click="closePopup">&times;</span>
      <h3>{{$t("flight-only.flight-details")}}</h3>

      <div class="flightdetails_innerbox">
        <div class="header-box">
          <span class="ml-2 mt-1"><i class="fa fa-plane"></i></span>
          {{$t("flight-only.from-to", {from: departureCityName , to: arriveCityName})}}
        </div>

        <div class="flightdetails_item">

          <!-- Departure -->
          <div class="title-box">
            <span><i class="far fa-calendar-alt"></i></span>
            {{ departureDate }}
          </div>

          <div class="flight-box">
            <span class="time-item">{{ data.FL_Dep_Hour }}</span>
            <span class="descript-item">{{ departureCityName }}</span>
            <span class="descript-item">{{ departureAirportName }}</span>
          </div>

          <div class="flight-box" v-if="isOverDayFromDepToStop">
            <span class="time-item"><i class="far fa-calendar-alt"></i> + 1</span>
            <span class="descript-item">{{ stopArriveDate }}</span>
          </div>

          <div class="flight-box">
            <span class="time-item">{{ stopArriveTime }}</span>
            <span class="descript-item">{{ stopCityName }}</span>
          </div>

          <!-- Stop in middle Airport -->
          <div class="title-box stopOverTime">
            <span><i class="far fa-clock"></i></span>
            {{ stopoverMessage }}
          </div>

          <div class="flight-box" v-if="isOverDayInStop">
            <span class="time-item"><i class="far fa-calendar-alt"></i> + 1</span>
            <span class="descript-item">{{ stopDepartureDate }}</span>
          </div>

          <div class="flight-box">
            <span class="time-item">{{ stopDepartureTime }}</span>
            <span class="descript-item">{{ stopAirportName }}</span>
          </div>

          <div class="flight-box" v-if="isOverDayFromStopToArrive">
            <span class="time-item"><i class="far fa-calendar-alt"></i> + 1</span>
            <span class="descript-item">{{ arriveDate }}</span>
          </div>

          <div class="flight-box">
            <span class="time-item">{{ data.FL_Arrv_Hour }}</span>
            <span class="descript-item">{{ arriveCityName }}</span>
            <span class="descript-item">{{ arriveAirportName }}</span>
          </div>

          <!-- Arrive -->
          <div class="title-box">
            <span><i class="far fa-calendar-alt"></i></span>
            {{ arriveDate }}
          </div>

        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';
import gMixin from '@/utils/mixins';
import imageUrlMixin from '@/utils/imageUrlMixin';

export default {
  name: 'flight-details-popup',
  mixins: [gMixin, imageUrlMixin],
  props: {
    data: {
      type: Object,
      default: null,
    },
    showFlag: {
      type: Boolean,
      default: false,
    },
    closePopup: {
      type: Function,
      default: () => {},
    },
  },
  components: {
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
    }),
    destinations() {
      const { destinationList } = this;
      return destinationList?.filter((dest) => dest.name) || [];
    },
    departureCityName() {
      const { data } = this;
      return this.getCityName(data.FL_From_Route);
    },
    departureAirportName() {
      const { data } = this;
      return this.getAirportName(data.FL_From_Air_Port);
    },
    departureDate() {
      const { data } = this;
      return this.convertDateFormat(data.FL_Date);
    },
    stopCityName() {
      const { data } = this;
      return this.getCityName(data.FL_Flt_Via);
    },
    stopAirportName() {
      const { data } = this;
      return this.getAirportName(data.FL_Via_Air_Port);
    },
    stopArriveDate() {
      const { data } = this;
      const date = dayjs(data.FL_Via_Hour);
      return date.format('DD-MM-YYYY');
    },
    stopArriveTime() {
      const { data } = this;
      const date = dayjs(data.FL_Via_Hour);
      return date.format('HH:mm');
    },
    stopDepartureDate() {
      const { data } = this;
      const date = dayjs(data.FL_ViaDep_Hour);
      return date.format('DD-MM-YYYY');
    },
    stopDepartureTime() {
      const { data } = this;
      const date = dayjs(data.FL_ViaDep_Hour);
      return date.format('HH:mm');
    },
    viaStopTimeHours() {
      if (!this.data) return 0;
      return Number(this.data.stopOverTime.split(':')[0]);
    },
    viaStopTimeMinutes() {
      if (!this.data) return 0;
      return Number(this.data.stopOverTime.split(':')[1]);
    },
    stopoverMessage() {
      if (this.viaStopTimeHours > 0 && this.viaStopTimeMinutes > 0) {
        return this.$t('flight-only.stopover-message', {
          hours: this.viaStopTimeHours,
          minutes: this.viaStopTimeMinutes,
        });
      } else if (this.viaStopTimeHours > 0) {
        return this.$t('flight-only.stopover-message-hours-only', {
          hours: this.viaStopTimeHours,
        });
      } else if (this.viaStopTimeMinutes > 0) {
        return this.$t('flight-only.stopover-message-minutes-only', {
          minutes: this.viaStopTimeMinutes,
        });
      }
      return '';
    },
    arriveCityName() {
      const { data } = this;
      return this.getCityName(data.FL_To_Route);
    },
    arriveAirportName() {
      const { data } = this;
      return this.getAirportName(data.FL_To_Air_Port);
    },
    arriveDate() {
      const { data } = this;
      return this.convertDateFormat(data.landingDate);
    },
    isOverDayFromDepToStop() {
      const { data } = this;
      return (dayjs(data.FL_Via_Hour).diff(dayjs(data.FL_Date), 'day') > 0);
    },
    isOverDayInStop() {
      const { data } = this;
      return (dayjs(data.FL_ViaDep_Hour).diff(dayjs(data.FL_Via_Hour), 'day') > 0);
    },
    isOverDayFromStopToArrive() {
      const { data } = this;
      return (dayjs(data.landingDate).diff(dayjs(data.FL_ViaDep_Hour), 'day') > 0);
    },
  },
  methods: {
    convertDateFormat(pDate, format = 'DD-MM-YYYY dddd') {
      return dayjs(pDate).format(format);
    },
    getCityName(code) {
      return this.data?.translations?.flightDestinationName?.[code]?.[this.lang] ?? code;
    },
    getAirportName(code) {
      return this.data?.translations?.airportName[code]?.[this.lang] ?? code;
    },
  },
  mounted() {
  },
};
</script>

<style scoped>
.popup {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
}
.popup-content {
  background-color: #fefefe;
  padding: 20px;
  border: 1px solid #888;
  border-radius: 10px;
  box-shadow: 0px 4px 5px 0px #767676;
  min-width: 400px;
}
.close-button {
  color: #aaa;
  float: right;
  margin-left: 20px;
  font-size: 36px;
  line-height: 36px;
  cursor: pointer;
}
.header-box {
  height: 48px;
  width: 100%;
  padding: 5px 15px;
  color: #132d42;
  font-size: 24px;
  line-height: 27px;
  display: flex;
  align-items: center;
}

.flightdetails_item .title-box {
  height: 48px;
  width: 100%;
  border-radius: 16px;
  background-color: #f3f3f3;
  box-sizing: border-box;
  padding: 5px 67px 5px 5px;
  color: #132d42;
  font-size: 18px;
  line-height: 27px;
  display: flex;
  align-items: center;
}
.flightdetails_item .title-box.stopOverTime{
  color: #ea281a;
  font-weight: 600;
}
.flightdetails_item .title-box span {
  min-width: 24px;
  max-width: 24px;
  margin-left: 10px;
}
.flightdetails_item .title-box span img {
  width: 100%;
  object-fit: contain;
}

.flightdetails_item .flight-box {
  height: 40px;
  width: 100%;
  padding: 0px 15px;
  color: #132d42;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
}
.flightdetails_item .flight-box .time-item {
  width: 60px !important;
  font-weight: 600;
  line-height: 40px;
  margin-left: 10px;
  padding-left: 10px;
  border-left: solid 1px #132d42;
}
.flightdetails_item .flight-box .descript-item {
  margin-right: 10px;
}

</style>
